<template>
	<div class="trialedit">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="客户信息"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 手机 -->
		<div class="trialedit__phone">
			<img src="@/assets/trial/icon_phone.png">
			<input
				type="number"
				placeholder-style="font-family: HYQiHeiY3-45; color: #878787;"
				placeholder="点击输入手机号"
				v-model="form.mobile"
			>
		</div>
		
		<!-- 姓名 -->
		<div class="trialedit__user">
			<img src="@/assets/trial/icon_user.png">
			<input
				type="text"
				placeholder-style="font-family: HYQiHeiY3-45; color: #878787;"
				placeholder="点击输入客户姓名（称呼）"
				v-model="form.name"
			>
		</div>
		
		<!-- 性别 -->
		<div class="trialedit__gender">
			<div :class="form.gender == 1 ? 'trialedit__gender--active' : ''" @click="form.gender = 1">男</div>
			<div :class="form.gender != 1 ? 'trialedit__gender--active' : ''" @click="form.gender = 0">女</div>
		</div>
		
		<div class="trialedit__save__box">
			<div class="trialedit__save" @click="handTrialEdit">
				保存修改
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			form: {
				number: '',
				name: '',
				mobile: '',
				gender: 0
			}
		};
	},
	mounted() {
		if (this.$route.query.number) this.form.number = this.$route.query.number
		this.getTrialInfo()
	},
	methods: {
		// 试驾详情
		async getTrialInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_info_1_7', {
				number: this.form.number
			})
			if (result.code == 1) {
				this.form.name = result.data.res.name
				this.form.mobile = result.data.res.mobile
				this.form.gender = result.data.res.gender
			}
		},
		
		// 保存
		async handTrialEdit(e) {
			if(this.form.mobile.length < 7 || this.form.mobile.length > 11) return this.$toast.fail('号码长度应为7~11位')
			if(this.form.name.length > 6) return this.$toast.fail('名称长度最大为6')
			const {data: result} = await this.$http.post('/api/scsj/edit_scsj_customer_info', this.form)
			if (result.code == 1) {
				this.$toast.success('保存成功')
				this.$router.go(-1)
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.trialedit__phone{
	margin: 24px auto 0;
	width: 686px;
	height: 88px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #6F6F6F;
	img{
		margin: 0 28px;
		width: 36px;
		height: 32px;
	}
	input{
		width: 570px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		border: none;
		background-color: rgba(255,255,255,0);
		color: #ECECEC;
	}
}
.trialedit__user{
	margin: 24px auto 0;
	width: 686px;
	height: 88px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #6F6F6F;
	img{
		margin: 0 28px;
		width: 36px;
		height: 32px;
	}
	input{
		border: none;
		background-color: rgba(255,255,255,0);
		width: 570px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		color: #ECECEC;
	}
}
.trialedit__gender{
	margin: 36px auto 0;
	width: 686px;
	display: flex;
	div{
		width: 125px;
		border: 1px solid #979797;
		font-size: 36px;
		font-family: HYQiHeiY3-45;
		color: #878787;
		text-align: center;
		line-height: 80px;
	}
	&--active{
		background: #D8D8D8;
		border: 1px solid #D8D8D8;
	}
}
.trialedit__save__box{
	margin-top: 60px;
	padding: 20px 32px;
}
.trialedit__save{
	width: 100%;
	line-height: 88px;
	background: #fa0037;
	font-size: 32px;
	font-family: HYQiHeiY3-45;
	color: #F4F4F4;
	text-align: center;
}
</style>
